@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.App {
  text-align: center;
}

body{
  background: #fff;
  font-family: "Lato", sans-serif;
}

/*PlayArrow*/
.play-group{
  border: none;
  width: fit-content;
  padding: 0;
  margin: 0;
}
.play-group:active{
  border: none;
  transform: scale(120%);
  -webkit-transform: scale(120%);
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}
.play-wrapper{
  position: relative;
  height: 100px;
  perspective: 2000px;
  perspective-origin: middle;
}
.play-wrapper:hover{
  transform: scale(120%);
  -webkit-transform: scale(120%);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.86,0,0.07,1);
  transition: transform 0.5s cubic-bezier(0.86,0,0.07,1); 
 
}
@keyframes autorotate{  
  0% {    
    transform: rotateY(0deg);
    
  } 
  100% {     
      transform: rotateY(360deg);      
     
  }
  
}
.play-wrapper .playcircle{
  animation: 2s autorotate; 
  animation-iteration-count: infinite;
  transition-timing-function: linear;
 
}
.playcircle{
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100%;
  transform-style: preserve-3d;
  /* transition: transform 0.8s cubic-bezier(0.86,0,0.07,1); */
  color: white;
  font-weight: bold;
}

.play-front, .play-back{
position: absolute;
height: 100%;
width: 100%;
padding: 30px;
border-radius: 8px;
/* backface-visibility: hidden;
-webkit-backface-visibility: hidden; */
cursor: pointer;
margin: 0 auto;
}
.arrow-play{
position: absolute;
top: 0;
left: 0;
height: 100%;
width: 100%;

border-radius: 8px;
margin: 0 auto;
}
.play-front{
/* background: #ed5d34; */
color: rgba(237, 67, 83, 0.8);
display: flex;
flex-direction: column;
justify-content: space-between;
}

/*End PlayArrow*/
.cards-container{
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
}
h1{
  color: #1a2f42;
  margin: 0 auto; 
  padding-bottom: 1px dashed gray;
}
h1 span {
  display: block;
  font-family: 'Julius Sans One', sans-serif;
}
h1 span:nth-child(1){
  
  font-weight: normal;
  color: #1a2f42;
}
.card-container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 10%;
  margin: 80px 0;
  align-self: center;
}
.card-container-alone{
 
  display: block;
  width: 100%;
  height: 500px;  
  margin: 0 auto;
  margin-top: 10em;
}
.card-wrapper{
  position: relative;
  height: 350px;
  perspective: 2000px;
  perspective-origin: top;
}
.card-wrapper:hover .card{
  transform: rotateY(180deg)
}
.card{
  width: 50%;
  margin: 0 auto;
  position: relative;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s cubic-bezier(0.86,0,0.07,1);
  color: white;
  font-weight: bold;
}
.card-front, .card-back{
position: absolute;
height: 100%;
width: 100%;
padding: 30px;
border-radius: 8px;
backface-visibility: hidden;
-webkit-backface-visibility: hidden;
cursor: pointer;
margin: 0 auto;
}
.card-front{
/* background: #ed5d34; */
background-color: rgba(237, 67, 83, 0.8);
display: flex;
flex-direction: column;
justify-content: space-between;
}
.card-wrapper:nth-child(even) .card-front{
  background-color: #1a2f42;
  
}

.card-front p{
  font-size: 2em;
  line-height: 35px;
}
.arrow-icon{
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.card-price span{
  font-size: 1.5em;
}
.card-back{
  /* background: #ed5d34; */
  background-color: #1a2f42;
  transform: rotateY(180deg); /*flip*/
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/wwf03.JPG");
  background-position: 100%;
  background-size:cover;
  background-repeat: no-repeat;
}
.card-wrapper:nth-child(even) .card-back{
  background-color: #1a2f42;
}
.card-back a{
  display: inline-block;
  border-radius: 10px;
  padding: 15px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: normal;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 1.5em;
  color: #333;
  transition: 
  background-color 0.3s ease-out,
  color 0.3s ease-out;
}
.card-wrapper:nth-child(odd) .card-back
a:hover{
  background-color: rgba(26, 47, 66, 0.5);
  color: #fff;

}
.card-wrapper:nth-child(even) .card-back
a:hover{
  /* background-color: #ed5d34; */
  background-color: #ED4353;
  color: #fff;
  
}

@media screen and (max-width: 992px) {
  .card{
    width: 80%;
    margin: 0 auto;
    position: relative;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.86,0,0.07,1);
    color: white;
    font-weight: bold;
  }
}