*{
  margin:0;
  padding:0;
}
 

body .postit{

    margin: 20px auto;
    font-family: 'Reenie Beanie';
    text-align: justify;  
    font-size: 2em;
  }
span .postit{

  font-weight: normal;
  text-align: center;

}
.form-btn{
  border-radius: 10px;
  border-style: none;
  background-color: rgba(106,100,90,.8);
  height: fit-content;
  width: 100px;
}
.form-btn:hover{
  filter: brightness(0.5);
  cursor: pointer;  
}

  h2.postit {
    font-weight: bold;
    font-size: 2rem;
  }
  textarea{
    font-family: 'Lato';
    font-variant: small-caps;
  }
  p.postit{
    
    display: block;  
    font-weight: normal;
    text-align: center;
    margin: 0 auto;
    padding: 1em;
   
  }
  p.postit-inputs{
     
    display: block;  
    font-weight: normal;
    text-align: center;
    margin-left: 3em;
    margin-right: 3em;
    padding: 1em;
  }
  .portfolio-gallery{
    position: relative;
  }
  ul,li{
    list-style:none;
  }
  ul{
    
    justify-content: center;
  }
  ul li a{
    text-decoration:none;
    color:#000;
    
    
  }
  .wrapper{
    background:#ffc;
    display:block;
    height:10em;
    width:10em;
    padding:1em;
    box-shadow: 5px 5px 7px rgba(33,33,33,.7);
    transform: rotate(-6deg);
    display: inline-flex;
    flex-wrap: wrap;
    transition: transform .15s linear;
    margin: 1em;
  }
  

  

  ul.postit li.postit{
    margin:1em;
  }
  .wrapper:nth-child(even){
    transform:rotate(4deg);
    position:relative;    
    top:5px;
    
  }
  .wrapper:nth-child(3n){
    transform:rotate(-3deg);
    position:relative;
    top:-5px;
  }
  .wrapper:nth-child(5n){
    transform:rotate(5deg);
    position:relative;
    top:-10px;
  }

  .wrapper:hover,.wrapper:focus{
    box-shadow:10px 10px 7px rgba(0,0,0,.7);
    transform: scale(1.25);
    position:relative;
    z-index:5;
  }

  p.postit-inputs{     
    display: grid;
    font-weight: normal;
    text-align: justify;
    /* margin-left: 1em; */
    /* margin-right: 1em; */
    padding: 1em;
    align-content: stretch;
    justify-content: center;
    align-items: baseline;
    justify-items: center;
  }