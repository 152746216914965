:root {
  --appBckgColor: rgba(245, 239, 230);
  --appFamily: 'Montserrat', sans-serif;
  --title1H1Family: 'Parisienne';
  --title1Family: 'Supermercado One', cursive;
  --title2Family: 'Julius Sans One', sans-serif;
  --title1Color: #F0604D;
  --title2Color: #1a2f42;
  --fontBold: 600;
  --fontLight: 400;
}
img{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #A1A27E;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100vw;

}

/*drag and drop elts*/
.DragApp {
  text-align: center;
}

.DrapApp-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .DragApp-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.DragApp-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.DragApp-link {
  color: #61dafb;
}

@keyframes DragApp-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**/
.ref-position{
  width: 150px;
  height: 150px;
  position: absolute;
  display: block;
  bottom: 0;
  left: 50%;
}

body {

  max-width: 100vw;
  margin: 0 auto;
}

.menu-span {
  color: #F0604D;
  font-weight: 500;
}

.arrow-wrapper {
  display: grid;
  grid-template-columns: 10% 80% 10%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2em;
  font-stretch: ultra-expanded;
  color: #282c34;

}

.arrow-div {
  display: flex;
  background-image: -webkit-linear-gradient(rgba(50, 50, 50, 0.8), rgba(10, 10, 10, 0.8));
  background-image: linear-gradient(rgba(50, 50, 50, 0.8), rgba(10, 10, 10, 0.8));
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.arrowpop{  
  position: relative;
  display: block;
  animation: 2s pop; 
  animation-iteration-count: infinite;
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
}
.arrowwait{  
  position: relative;
  display: block;

}
.arrow-position{
  transform: rotate(-90deg);
  position: relative;
  display: block;
  width: fit-content;
  height: fit-content;
 
}
@keyframes pop{  
  0% {    
    transform: translateX(-50px);
    opacity: 0.5;  
    border-color: rgba(107, 237, 67, 0.8);
    color: rgb(107, 237, 67);
  } 
  50% {
      opacity: 1;
      transform: translateX(25px);      
      border-color: rgba(107, 237, 67, 0.2);
      color:rgb(107, 237, 67);
  }
  100% {
    opacity: 0.5;
    transform: translateX(0px);
    color:rgba(107, 237, 67);
    border-color: rgba(107, 237, 67, 0.5);
  }
}

@keyframes blub{  
  0% {    
    transform: scale(50%);   
  } 
  5% {     
      transform: scale(110%); 
  }
  10%{    
    transform: scaleY(100%);    
  }
  50%{
    transform: scaleY(100%);
  }

}
.p-services-details{
  color: var(--title2Color);
  font-variant: small-caps;
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.6em;
  font-weight: 600;
}
.prez-services{
  margin: 0 auto;
  display: block;
  margin-right: 6em;
  margin-left: 6em;
  line-height: 2.5em;
  text-align: center;
}
.points-position {
  display: inline-flex;
  align-items: baseline;
  text-align: right;
}
h3{
  font-family: var(--title2Family);
  color: var(--title1Color);
  margin: 0 auto;
  margin-top: 2em;
  margin-bottom: 2em;
}
.prez-details{
  text-align: justify;
 
}
.text-area {
  width: 100%;
  height: 100%;
  align-items: baseline;
  text-align: left;
}



.spacy-span {
  text-align: center;
  font-size: 3em;

  color: #1a2f42;

}

.animated-points {
  animation: .5s linear 1s infinite alternate slidein;
  text-align: left;
  font-size: 5em;
  padding: 0.2em;
}

.info-position {
  display: grid;
  grid-template-columns: minmax(50px, auto);
  position: absolute;
  left: 0;
  top: 0;
  max-width: 100vw;
  width: 100%;
  height: 10em;
  margin: 0 auto;
  align-items: baseline;
  text-align: center;

}

.invisible-div {
  opacity: 0;
}

.centered-div {
  text-align: center;
  align-self: center;
}

.right-arrow {
  transform: rotate(-90deg);
  border-style: none;
  cursor: pointer;
  font-size: xx-large;
  color: #282c34;
}

.fake-form span {
  font-size: 0.3em;
}

.delivery p {
  line-height: 0.5em;
}

.delivery h2 {
  font-size: 0.5em;
  font-weight: 600;
  color: black;
}

.delivery span {
  font-size: 0.3em;

}

.fake-addtokart {
  font-size: 0.3em;
  border: 1px dotted;
  border-radius: 2%;
}

.left-arrow {
  transform: rotate(90deg);
  border-style: none;
  cursor: pointer;
  font-size: xx-large;
  color: #282c34;
}

.container {
  width: 80%;
  margin: 0 auto;
  height: 150px;
  align-self: center;
  position: relative;
  z-index: 3000;


}

.SwipeElt {
  touch-action: manipulation;
}

/*About animated buttons*/
.image-ok {
  position: absolute;
  width: inherit;
  height: fit-content;
  top: 0;
  left: 0;
  opacity: 1;
}

.image-down {
  position: absolute;
  width: inherit;
  height: inherit;
  top: 0;
  left: 0;
  opacity: 0;
}

.word {
  display: inline-flex;
  position: relative;
  
  
}
.loading-word{
  position: relative;
  width:100%;
    height: 100%;
    display: block;
  
}

.buttons-position {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: inline-flex;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100vw;
  padding-bottom: 2em;
  padding-top: 2rem;
}

.button-relative-position {
  position: relative;
  width: 100%;
  height: auto;

  background-color: snow;

}
.site-btn{
  display: inline-block;
  border-radius: 10px;
  padding: 15px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: normal;
  color: #fff;
  background-color: #1a2f42;
  font-size: 1.5em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  
  transition: 
  background-color 0.3s ease-out,
  color 0.3s ease-out;
}
.site-btn:hover{
  
  background-color: rgba(255, 255, 255, 0.6);  
  color: #333;
}
.btn-z {
  z-index: 3000;
  background-color: red;
}

.btn-mask {
  display: block;
  padding: 10px;
  /* grid-template-columns: 10% 80% 10%;
  grid-template-rows: 10% 80% 10%; */
  width: 200px;
  height: 200px;
  cursor: pointer;
}

.btn-mask::before {
  content: "";
  width: 50px;
  height: 100%;

  border-color: yellow;
}

.button-relative-position :hover {
  cursor: pointer;


}

.button-relative-position :active {
  cursor: pointer;


}
.copyright{
  padding: 1rem;
  padding-right: 2rem;
  text-align: justify;
}
.projet-div {
  display: block;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 2em;
  margin-right: 4rem;
  max-width: 100vw;
}

.grecaptcha-badge {
  display: none;
}

.title-inline {
  display: inline-block;
}

.photo-al {
  display: inline-block;
  opacity: 1;
  object-fit: contain;
}

ul,
li {
  font-family: 'Montserrat', sans-serif;
}

.presta-card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;
  justify-content: flex-start;
  align-items: center;
}

.info-title {
  font-family: 'Cinzel Decorative', cursive;
  /* color: #D7572B; */
  color: #ED4353;
  font-size: 1.6em;
  padding: 2em;
}

._info-grid {
  display: grid;
  position: relative;
 
  grid-template-columns: 75% 25%;
  width: 100%;
  height: 100%;
  column-gap: 2rem;
  grid-row-gap: 2rem;
  margin: 0 auto;
  margin-top: 10rem;
}

.info-grid {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 2em;
}

.relative-div-replace-absolute {
  display: block;
  position: relative;
  height: 20em;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.section-wrapper {
  position: relative;
  max-width: 100vw;
  width: 100%;
  height: 100%;
  margin: 0 auto;

}

.portfolio-gallery {
  margin: 0 auto;


}

.portfolio-sections {
  display: block;


}

.portfolio-videos {
  margin: 0 auto;
  width: max-content;

}

.portfolio-video {
  display: inline-flex;
  position: relative;
  margin: 0.8em;
  margin-bottom: 2em;
}

h1 {
  font-family: 'Julius Sans One', sans-serif;
  color: #A1A27E;
  padding: 0.5em;

}

h2 {
  font-family: 'Julius Sans One', sans-serif;
  color: #1a2f42;
  font-size: 1.5em;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 1em;
  padding-top: 1em;
}

.title1 {
  font-family: var(--title1H1Family);
  /* color: #D7572B; */
  color: #ED4353;
  font-size: 3.5em;

}

.title1-div {}

.frame-sections {
  position: relative;
  width: 100%;
  height: 150px;

}

.title2 {
  padding-top: 1em;
  font-family: 'Julius Sans One', sans-serif;
  /* color: #F1895C; */
  color: var(--title1Color);
  width: 80%;
  margin-bottom: 1.5em;
}
.title2-price{
  padding: 0em;
  font-family: 'Julius Sans One', sans-serif;
  /* color: #F1895C; */
  color: var(--title1Color);
  width: 80%;
}
.title-carousel {
  padding-top: 1em;
  font-family: 'Julius Sans One', sans-serif;
  /* color: #F1895C; */
  color: var(--title1Color);
  width: 100%;
  font-size: 2em;
}
.title-carousel span{
  color: var(--title2Color)
}
.title2 span{
  color: var(--title2Color)
}
.title3 {
  font-family: 'Julius Sans One', sans-serif;
  color: #F38071;
}

.title3-blck {
  font-family: 'Julius Sans One', sans-serif;
  color: #392E2C;
}

.title4 {
  font-family: 'Cinzel Decorative', cursive;
  color: #CE8F8A;
}

.subTitle1 {
  font-family: 'La Belle Aurore', cursive;
  color: #CE8F8A;
}

.subTitle2 {
  color: #1a2f42;
  margin: 0 auto; 
  text-align: center;
}


.domain {
  position: absolute;

}

.img-carousel {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.noselect{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.img-compass {
  position: relative;
  height: 100%;
  width: auto;
  max-width: 100vw;
  object-fit: scale-down;
  z-index: 1000;
}

.img-foranim {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: scale-down;
  z-index: 1000;
}

.img-compass:hover,
.img-compass:active {
  filter: brightness(1.2);
  cursor: pointer;

}

.card-arrow {}

.bckg-compass {
  position: absolute;
  object-fit: scale-down;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.compass-btn {
  border-style: none;
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 100%;

}

.img-btn {
  border-style: none;
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 100%;

}

.compass-btn-open {
  border-style: none;
  position: relative;
  z-index: 1000;

}

.compass-btn-open:hover {
  cursor: pointer;
}

.compass-btn :hover {
  cursor: pointer;
}

.section-details {
  position: relative;
  z-index: 5000;

}

/* .info-block{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
} */


.sections-button {}

.about-h1 {
  font-family: 'Julius Sans One', sans-serif;
  color: #A1A27E;
  padding: 0.8em;
  font-weight: 600;
  font-size: 1em;
}

.about-sections {
  position: relative;
  text-align: center;
  display: block;
  width: inherit;
  margin: 0 auto;
  /* background-color: #EED7C5; */

}

._about-sections {

  display: grid;
  grid-template-columns: 22% 22% 22% 20%;
  column-gap: 2%;
  width: inherit;
  margin: 0 auto;
  /* background-color: #EED7C5; */

}

.img_position {
  position: relative;
}

.center-div {
  display: block;
  margin: 0 5em;
}

.title-div {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  margin: 0 auto;
  text-align: -webkit-center;
  align-items: self-start;
}

.div-for-grid {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  text-align: center;
}

.bckg-position {
  height: 50em;
}

.fixed-bckg-00 {
  width: 100%;
  height: 50vh;
  background-image: url("../images/background02.jpg");
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.8;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-01 {
  width: 100%;
  height: 50vh;
  background-image: url("../images/background04.jpg");
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.8;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-02 {
  width: 100%;
  height: 50vh;
  background-image: url("../images/background03.jpg");
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.8;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-03 {
  width: 100%;
  height: 50vh;
  background-image: url("../images/background02.jpg");
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.8;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-04 {
  width: 100%;
  height: 50vh;
  background-image: url("../images/background.jpg");
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.8;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.about-section-img {
  width: 100%;

  z-index: 1000;
  object-fit: scale-down;

}

.about-section {
  width: inherit;
  height: inherit;
  display: inline-block;
  margin: 0 auto;

}

.compass-div {
  display: block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  align-self: center;
  text-align: center;
}

div.compass {
  width: 100%;
  height: 100%;
}

.sections-wrapper {
  display: block;

}

.theCarousel {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-clip-path: inset(0px 0px 0px 0px);
  clip-path: inset(0px 0px 0px 0px);
}

.img-wrapper {
  position: relative;
  width: 100%;
}

.thecarouselnav {
  z-index: 20000;
  position: relative;

  width: 100%;
  height: 100%;
  display: inline-flex;
  display: -ms-inline-flexbox;
  /* transform: rotate(
90deg
); */
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.carouselgrid {
  display: block;
}

.theweb {
  display: grid;
  grid-template-rows: 30% 70%;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: fit-content;
  align-self: center;
}

.carousel-wrapper {
  display: block;

}

.carousel-container {
  width: 100vw;
  display: grid;
  grid-template-columns: 65% 5% 20% 4%;
  column-gap: 2%;
  background-color: #EED7C5;
  height: 60vh;
}

.domains-align {
  width: 100%;
  height: 30%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

}

.web-bowl-home {
  display: block;
  position: relative;
  width: 60%;
  height: 60%;
  margin: 0 auto;
  cursor: pointer;
  z-index: 500000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.web-bowl-home:hover {
  cursor: pointer;
}

.carousel-div {

  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 2em;
}

.carouselnav-wrapper {
  display: flex;
  position: relative;
  height: 100%;
  width: inherit;
  background-color: #EED7C5;
  align-self: center;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;

}

.nav-wrapper {
  position: relative;
  min-height: 100%;
}

.side-nav-wrapper {
  position: relative;
  min-width: 100%;
}
.side-nav{
  background-color: rgba(250,250,250,0.8);
  display: inline;
  position: relative;   
 
}
.nav-btn {
  border-style: none;
  width: fit-content;
  height: fit-content;
}
.key-position{
  position: absolute;
  display: block;  
  top: 0;
  left: 0;
}

.key-scale{
  animation: 5s blub; 
  animation-iteration-count: infinite;
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
}

.btn-test {
  z-index: 500000;

}

.btn-test:hover {
  cursor: pointer;
}

.title-position {
  position: relative;
  width: 100%;
  max-width: 100vw;
  height: 6.1em;
  margin: 0;
  margin-top: 2.5em;
  overflow-x: hidden;
  overflow: hidden;
}

button {
  background-color: rgba(0, 0, 0, 0);
}

._route-section {
  height: fit-content;
  display: block;
  position: relative;
  width: 100vw;

}

.route-section {
  height: fit-content;
  display: block;
  position: relative;
  width: 100%;
  max-width: 100vw;

}

.align-proj-section {
  padding: 5em;
}

.invisible-title {
  color: rgba(0, 0, 0, 0);

}

.section-block {
  width: 100vw;
  margin: 0 auto;
}

.section-button {
  width: fit-content;
  border-style: none;


}

.section-button:focus {
  border-style: none;
  border-color: rgba(0, 0, 0, 0);
  outline: none;
}

@keyframes slideIn {

  0% {
    transform: skewX(0deg);
    transform: scaleX(0%);
    transform: translateX(-100%);

  }

  25% {
    transform: skewX(0deg);
    transform: scaleX(0%);

  }

  50% {
    transform: skewX(50deg);
    transform: scaleX(120%);

  }

  75% {
    transform: skewX(0deg);
    transform: scaleX(100%);
    transform: translateX(0%);
  }

  100% {
    transform: scaleX(100%);
    transform: translateX(0%);
  }

}

@keyframes scale {
  0% {

    transform: scaleY(0%);
  }


  100% {

    transform: scaleY(100%);
  }

}

.wrapper {
  background-color: indianred;
  opacity: 0.5;
  width: 700px;
  height: 300px;
  margin: 20px;
  text-align: center;
  vertical-align: middle;

}

.fake-input {
  z-index: 5;
}

.fake-form {
  width: inherit;
  height: inherit;
  z-index: 5;
  background-color: rgb(182, 178, 169);
}

.frames {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;


}

.carefull-span {}

.house-frame-position {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  position: relative;
  margin: 0 auto;

}

.frame-position {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  position: relative;
  margin: 0 auto;

}

.letters-position {
  position: relative;
  display: block;
  top: -20%;
  left: -20%;
  transform: rotate(70deg);
  font-size: 1em;
}

.invisible-frame {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  position: relative;
  margin: 0 auto;
  opacity: 0;
}

.frames-zindex {
  display: grid;
  grid-template-columns: 70% 30%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;


}

.stock {
  color: green;
  font-size: 0.5em;
}

button {
  font-family: 'Montserrat', sans-serif;
  border-style: 1px;
  border-color: rgb(182, 178, 169);
  padding: 1em;
  border-radius: 5%;
  cursor: pointer;

}

select {
  font-family: 'Montserrat', sans-serif;
  height: 3em;
  width: 3em;
  background-color: rgb(182, 178, 169);
  border-radius: 5%;
  border-style: none;
  border-bottom: rgba(20, 20, 20, 0.8)
}

input {
  font-family: 'Montserrat', sans-serif;
  background-color: none;
  border-style: none;
  border-bottom: 1px black;
  height: 2em;
  width: inherit;
}

input:active {
  border-style: none;

}

.center-div {
  display: block;

}

.dev-card {
  background-color: "#9999";
  opacity: 1;
  display: block;
  height: fit-content;
  width: 100%;
  padding: 1em;
  box-shadow: 0 2px 3px rgba(237, 67, 83, 0.4), 0 6px 20px rgba(237, 67, 83, 0.5);

  display: inline-flex;
  flex-wrap: wrap;
  transition: transform .15s linear;
  margin: 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(237, 67, 83);
  border-radius: 20px 10px 10px;
}

._dev-card {
  background: #c8c9a8bb;
  opacity: 1;
  display: block;
  height: fit-content;
  width: 10em;
  padding: 1em;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, .7);
  transform: rotate(-2deg);
  display: inline-flex;
  flex-wrap: wrap;
  transition: transform .15s linear;
  margin: 0 auto;
  border-style: solid;
  border-color: ivory;
  border-radius: 20px 40px 10px;
}

.dev-card:nth-child(even) {

  position: relative;
  top: 5px;
}

.dev-card:hover,
.dev-card:focus {
  box-shadow: 0 1px 2px rgba(237, 67, 83, 0.7), 0 3px 10px rgba(237, 67, 83, 0.8);
  transform: scaleY(1.2);
  position: relative;
  z-index: 5;

}

._dev-card:hover,
.dev-card:focus {
  box-shadow: 0 2px 3px rgba(237, 67, 83, 0.7), 0 6px 20px rgba(237, 67, 83, 0.8);
  transform: scale(1.2);
  position: relative;
  z-index: 5;

}


.img-dev-card {

  width: 100%;
  height: auto;
}

.img-dev-card:hover,
.img-dev-card:focus {
  filter: brightness(1.10);
  cursor: pointer;
}

.dev-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
}

.btn-goToProjectwrapper {
  background-color: rgba(20, 20, 20, 0.8);
  border-radius: 5px;
  padding: 1em;
  width: 20em;
  margin: 0 auto;
  margin-top: 2em;

}

.btn-gotoProject {
  box-shadow: 5px 5px 7px rgba(33, 33, 33, .7);
  border-radius: 6px;
  background-color: rgba(200, 200, 200, 0.8);
  border-style: none;
  cursor: pointer;
  padding: 1em;
  min-height: fit-content;
  width: inherit;
}

.xp-details {
  padding: 0.5em;
  padding-left: 1em;
}

.xp-details-title {
  padding: 0.5em;
  padding-left: 1em;
  color: #ED4353;
}

.wrapper-details {
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 15%;
  column-gap: 5%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;

}

.btn-gotoProject:hover,
.btn-gotoProject:active {
  filter: brightness(1.25);
  transform: scale(1.15);
}

.btn-goToProjectwrapper:hover,
.btn-goToProjectwrapper:active {
  filter: brightness(1.25);
  transform: scale(1.05);
}

.project-p {
  margin: 1em;
}

.inv-btn-gotoproj {
  width: 0;
  height: 0;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(245, 239, 230, 0.8);

}

.info-div {
  height: fit-content;
  display: block;
  position: relative;
}

.infosection-header {

  padding-top: 1em;
  display: grid;
  grid-template-columns: 20% 70%;
  column-gap: 10%;
}

.infosection-wrapper {
  display: block;
  text-align: left;

}
.draps-wrapper{
  position: relative;
  width: inherit;
  height: 100%; 
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.8;
}

.service-card-block{
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 2px 3px rgba(237, 67, 83, 0.4), 0 6px 20px rgba(237, 67, 83, 0.5);
  border-radius: 50px 2% 50px 2%;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 1em;

}
.maintenance-wrapper {
  text-align: center;
  margin: 3em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
  height: fit-content;
}

.info-wrapper {
  text-align: center;
  display: block;
  margin: 3rem;
  margin-right: 4rem;
}

.info h2 {
  margin: 0 auto;
  margin-top: 3em;
}
.info{
  line-height: 2em;
}
.price{
  position: relative;
  display: block;
  margin: 0 auto;  
  margin-bottom: 2em;
}
.price-title{
  position: relative;
  display: block;
  margin: 0 auto;  
  margin-bottom: 2em;
  margin-top: 1em;
}
.price-title span{
  font-variant: small-caps;
  color: var(--title2Color);
  font-weight: 700;
}

.draps-title{
  margin: 0 auto;
  margin-top: 3em;
  z-index: 5001;
}
.draps-title h2{
  color: white;
  filter:brightness(2);
}
.info-container {
  width: 100%;
  height: 100%;
  text-align: justify;
  display: grid;
  grid-template-columns: 35% 55%;
  grid-row-gap: 10%;
  grid-column-gap: 10%;
  align-items: center;
}

.info-container:nth-child(2n) {
  grid-template-columns: 55% 35%;
}

.info-text-zone {
  margin-top: 2em;
  line-height: 2em;
  
}

.info-icon {
  border-style: dotted;
  border-color: #282c34;
  width: 100px;
  height: 100px;
  align-content: space-around;
  border-radius: 50%;
  display: flex;
  margin: 0 auto;
  background: rgba(200, 200, 200, 0.8);
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-icon span {
  text-align: center;
  align-self: center;
}

.title-deco {
  color: #6A645A;
  font-weight: bold;
}

.presta-description {
  font-size: smaller;
}

.presta-wrapper {
  text-align: center;
  margin: 2em;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
}

.App-link {
  color: #61dafb;
}


.invisiblebutton {
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  border-image: none;
  appearance: none;
}

.noborderbutton {

  border-color: rgba(0, 0, 0, 0);

}

.noborderbutton:disabled {
  background-color: red;
  height: fit-content;
  width: fit-content;
}

.invisiblebutton:focus {
  background-color: red;
  border-style: none;
  border-color: white;
  border-image: none;
  box-shadow: white;
  transform: scale(2);
}

.invisiblebutton:hover {
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  border-image: none;
  appearance: none;
}

/*SCROLLBAR*/


body::-webkit-scrollbar {
  overflow-x: hidden;
  width: 5px;
  /* width of the entire scrollbar */

}

body::-webkit-scrollbar-track {
  background: #6A645A;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {

  /* color of the scroll thumb */
  background-image: -webkit-linear-gradient(top,
      #2f2c09 0%,
      #bba803 100%);
  /* border-radius: 5px; */
  /* roundness of the scroll thumb */
  border: 3px solid #6A645A;
  /* creates padding around scroll thumb */
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */

}

::-webkit-scrollbar-track-piece {
  /*after trackbar*/
  height: 60%;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-image: -webkit-linear-gradient(top,
      #bba803 0%,
      #f8fc2d 100%);
}

/*Firefox*/
body {
  overflow-x: hidden;
  scrollbar-width: none;
  /* "auto" or "thin" */
  scrollbar-color:  #2f2c09 #bba803;
  /* scroll thumb and track */
}

.logo-business-div {
  /* background-color: rgba(106, 100, 90, 1); */
  background-color: rgba(245, 239, 230);
  border-color: rgb(237, 67, 83);
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 2px 3px rgba(237, 67, 83, 0.4), 0 6px 20px rgba(237, 67, 83, 0.5);

}

.zoom-img-position {
  position: absolute;
  height: fit-content;
}

.logo-business-img {
  filter: grayscale();
  filter: sepia();
  height: 10vh;
  width: auto;
  display: inline-flex;
  padding: 0.5em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.open_contact {
  font-size: 10em;
  text-align: center;
  border-style: solid;
  color: #ED4353;
}

.contact-section {
  height: fit-content;
  min-height: 110rem;
  line-height: 1.5em;
 background-color: var(--appBckgColor);
}

.contact-label {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.5em;
  padding: 0.5em;
  color: var(--title2Color);

}

p.contact-txtarea {
  text-align: left;
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  width: 100%;

}

.contact-input {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.5em;
  background-color: rgba(245, 239, 230);
  border-radius: 5px;
  border-style: none;
  padding: 2px;
  width: fit-content;
  min-width: 10em;
}
.img-bandeau{
  width: 100%;
  margin-top: 2em;
  position: relative;
  height: 10em;
  object-fit: cover;
}
.title-integration{
  position: relative;
  display: grid;
  grid-template-columns: 35% 65%;
  margin: 10%;
  align-content: center;
  border: 1px solid #1a2f42;
  border-radius: 3px;
 
}
.title-title-integration{
  position: relative;
  align-self: center;
  
}
.title-integration-leftside{
  position: relative;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
    padding-left: 1em;
}
.retro-container{
  margin: 0 auto;
  width: 100%;
  height: fit-content;
  display: grid;
  position: relative;
  grid-template-columns: 20% 50% 20%;
  column-gap: 3%;
  max-width: 100vw;
  margin-bottom: 1rem;
}
.retro-wrapper{
  display: block;
  text-align: center;
  margin: 0;
  width: 100%;
  height: fit-content;
  margin-bottom: 5rem;
  margin-top: 5rem;
}
.img-clip-left{
  width: 100%;
    display: inline-flex;
    position: relative;
    height: auto;
    background-color: #1a2f42;
    clip-path: polygon(35% 0, 100% 0%, 100% 100%, 0 100%);
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: flex-end;
    justify-content: center;
    align-items: center;
}
.img-site{
  width: 80%;
  height: auto;
}
.clip-right{
  width: 100%;
  background-color: #6A645A;
  position: relative;
  height: 100%; 
  clip-path: polygon(0% 0, 100% 0%, 80% 100%, 0 100%);
}
.clip-arrow{
  width: 100%;
  background-color: #6A645A;
  position: relative;
  height: 100%; 
  clip-path: polygon(0% 0%, 50% 50%, 50% 50%, 0 100%);
}
.border-retro-title{
  height: 30px;
  width: 100%;
  border-bottom: 2px solid #1a2f42;
}
h2.retro-title{
  text-align: center;
  font-size: 1.8em;
}

@media screen and (max-width: 992px) {
  .img-clip-left{
    clip-path: none;
  }
  .title-integration{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding-bottom: 2em;
   
  }
  .info-container {
    width: 100%;
    height: 100%;
    text-align: justify;
    display: block;
    line-height: 1.5em;
  }

  .letters-position {
    position: relative;
    display: block;
    top: -10%;
    left: -50%;
    transform: rotate(70deg);
  }


  .carousel-div {
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .carousel-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 60% 0% 25%;
  }

  .arrow-wrapper {
    display: block;
  }

  .arrow-div {
    display: none;
  }

  .buttons-position {
    position: relative;
    display: inline-flex;
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }

  h2 {
    font-size: 1.5em;
  }

  h1 {
    font-size: 1.8em;

  }

  .h1-section {
    font-size: 0.8em;
    font-family: 'Julius Sans One', sans-serif;
    color: #A1A27E;
    padding: 1em;
    font-weight: 700;
  }

  .about-h1 {
    font-size: 0.8em;
  }

  .h1-section {
    font-size: 1.5em;
  }

  .title1 {
    text-align: center;
    font-size: 2.5em;
  }

  .theCarousel {
    height: 100%;
    position: relative;
    width: 100%;

  }

  .dev-wrapper {
    display: block;
  }

  .dev-card {
    max-width: 70%;
  }

  .dev-card:nth-child(even) {
    transform: none;
    position: relative;
    top: 5px;
  }

  .card-wrapper {
    margin-top: 5em;
  }

  .wrapper-details {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    position: relative;
    text-align: center;
  }

  .route-section {
    font-size: smaller;

  }

  .portfolio-sections {
    display: block;
    margin: 0 auto;
  }

  .portfolio-videos {
    margin: 0 auto;
    display: block;
    width: 100%;
    height: auto;
    margin-top: 1em;
  }

  .info-grid {
    display: block;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 2em;
  }

  .relative-div-replace-absolute {
    display: block;
    position: relative;
    height: 22em;

  }

  .compass-div {
    display: grid;
    position: relative;
    margin: 0 auto;
    width: 100%;


  }

  .compass-phone {
    margin-left: 5em;
  }

}
