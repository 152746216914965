/*scrollingCarousel*/
.go-block{
  
    text-align: justify;
    display: block;
    height: fit-content;
    height: -moz-fit-content;
    max-width: 100vw;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 2rem;
  
}
.buttons-block{
z-index: 10000;
   position: relative;
    
}

.scrolling-block{    
    max-width: 100vw;
    display: grid;
    grid-template-columns: 80% 10%;
    width: 100%;
    height: 600px;
    perspective: 3000px;
    perspective-origin: center center;
    margin: 0 auto;   
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
}
.elts-block{
    display: block;
    position: relative;
    width: inherit;
    height: inherit;
}
.scroll-buttons{

    cursor: pointer;
    font-family: 'Arimo';
    font-size: 5em;
    opacity: 0.8;
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0 auto;
    color: rgba(69, 70, 55, 0.911);
    /* background-color: rgba(253, 237, 5, 0.7); */
    border-radius: 1% 20% 1% 20%;
    border-style: none;
 
}

.scroll-buttons:hover{
    border-style: none;
    transform: scale(1.15);
    filter: brightness(1.25) saturate(1.25);
    background-color: antiquewhite;
    -webkit-transform: scale(1.15);
    -webkit-transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) ;
}
.scroll-buttons:active{
    border-style: none;
    transform: scale(1.5);
    filter: brightness(1.25) saturate(1.25);
    -webkit-filter: brightness(1.25) saturate(1.15);
    -webkit-transform: scale(1.5);
    -webkit-transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) ;
}
.scroll-container{
    width: 80vw;
    height: 600px;     
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    margin: 0 auto;   
}
._scroll-wrapper{
    width: 500vw;
    height: inherit;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    transition: transform 0.8s cubic-bezier(0.86,0,0.07,1);
}

.scroll-wrapper{
    width: 640vw;
    height: inherit;
    display: inline-flex; 
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    transition: transform 0.8s cubic-bezier(0.86,0,0.07,1);
}

.body{
    color:rgb(112, 100, 76);
}
.block-title{
    font-family: 'Julius Sans One', sans-serif;
    font-size: 1em;
    color:rgba(245, 235, 237, 1);
    line-height: 1.2em;
 
}
.block-img{
    position: relative;   
    width: 80%;
    height: 100%;
    margin: 0 auto;
    opacity: 1;
    transition-property: opacity;
    -webkit-transition-property: opacity;
    transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
}
.no-block-img{
    opacity: 0;
    transition-property: opacity;
    -webkit-transition-property: opacity;
    transition-timing-function: ease-out;
    -webkit-transition-timing-function: ease-out;
    
    
}

.block-background{
    width: 100%;
    height: 100%;
    object-fit: contain;
   
}
.animation-frame{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;    
    border-style: dotted;
    border: 1px;
    border-color: black;
  }
  .img-for-iterate{
      display: block;
      position: absolute;
      
  }

.block-content{
    position: absolute;
    bottom: -2em;
    right: -1em;
    z-index: 1000;
    /* background-color: rgba(241, 225, 1, 0.8); */
    background-color: rgba(237, 67, 83, 0.8);
    color:rgba(245, 235, 237, 1);
    height: 7em;
    width: 8em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 20% 1% 20% 1%;
}
.block-icon{   
    padding: 5px;
    width: 9em;
    height: 9em;
    align-content: space-around;
    align-items: center;
    border-radius: 50%;
    display: flex;
    margin: 0 auto;
    margin-top: 1em;
    background-color: #FAF2EA;
    box-shadow: 2px 5px 2px #ED4353;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;   
    top: 0em;
    left: -5em;
  }
  .block-icon span{
  text-align: center;
  align-self: center;
  font-size: 1.2em;
  transform: rotateZ(-15deg);
  color: rgba(237, 67, 83, 0.8);
  }

.v-slider-block{
    font-size: 30px;    
    width: 100vw;
    height: inherit;  
    /* scroll-snap-align: center; */
    transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    margin-bottom: 3em;
    
}
.invisible-button{
    opacity: 0;
}
.bowl-btn{
    border-style: none;
    height: 150px;
    width: 150px;
    position: relative;
}
.v-slider-block:nth-child(1){
    background-repeat: no-repeat;
    background-position-x: bottom;
  
    
}

.v-slider-block:nth-child(2){}
.v-slider-block:nth-child(3){}
.v-slider-block:nth-child(4){}
.v-slider-block:nth-child(5){}

/*FAKE ARROWS not used*/
.arrow-button{
    border-style: none;
    width: inherit;
    height: inherit;
    cursor: pointer;
    position: absolute;
  
}
.arrow-abs{
    position: absolute;
    bottom: 0;
}
.group-container{
    display: grid;
    grid-template-columns: 80% 20%;
}
.arrow-container{
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: flex-end;
    align-items: baseline;
}
/*SCROLLBAR*/


.scroll-container::-webkit-scrollbar {
    overflow-y: hidden;
    height: 50px; /* width of the entire scrollbar */
  
  }
  /*arrows*/
 .scroll-container::-webkit-scrollbar-button {
    background-size: 100%;
    height: 50px;
    width: 50px; 
    cursor: pointer;
   
}


.scroll-container::-webkit-scrollbar-button:horizontal:increment {
    background-repeat: no-repeat;
    background-image: url("../svg/Arrow_right.svg");
    
}


.scroll-container::-webkit-scrollbar-button:horizontal:decrement {
    background-repeat: no-repeat;   
    background-image: url("../svg/Arrow_left.svg");
}

  .scroll-container::-webkit-scrollbar-track {
    
    background: rgba(168, 161, 161, 0);        /* color of the tracking area */
    
  }
  
  .scroll-container::-webkit-scrollbar-thumb {
   
    /* color of the scroll thumb */
    background-image: -webkit-linear-gradient(top,
                        rgba(29, 28, 28, 0) 0%,
                        rgba(168, 161, 161, 0) 95%,                                              
                        rgba(12, 11, 11, 1) 100%);
   border-radius: 2px;      /* roundness of the scroll thumb */
    /* border: 1px dotted #f1f1f1; */  /* creates padding around scroll thumb */
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
    
  }
  .scroll-container::-webkit-scrollbar-track-piece{ /*after trackbar*/
    width: 10%;
    
  }
  /* Handle on hover */
  .scroll-container::-webkit-scrollbar-thumb:hover {
    background-image: -webkit-linear-gradient(top,
                        rgba(168, 161, 161, 0) 0%,
                        rgba(168, 161, 161, 0) 95%,
                                              
                        rgba(12,12,12,0.5) 100%);
  }
  /*Firefox*/
  .scroll-container body {
    scroll-behavior: smooth;
    overflow-y:hidden;
    scrollbar-width: none; /* "auto" or "thin" */
    scrollbar-color: rgba(168, 161, 161, 0);   /* scroll thumb and track */
  }

  @media screen and (max-width: 992px) {
    .go-block{
        padding-bottom: 0;
    }
    .scrolling-block{
        display: grid;
        grid-template-columns: 80%;
        grid-template-rows: 80% 10%;
        overflow-x: hidden;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        height: 30em;
    }
    .buttons-block{
        z-index: 10000;
        position: relative;
      
    }
    
    
}